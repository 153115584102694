import Vue from 'vue';

async function haveSuccess(res) {
  if (res.status === 401 || res.status === 403) {
    var url = location.href;
    var pos = url.indexOf('#');
    if (pos > 0) {
      location.href = url.substring(0, pos) + '#/login';
    } else {
      location.href = url + '#/login';
    }
  }
  if ((res.status !== 200 && res.data.error !== 'success') || res.status !== 204) {
    Vue.$toast.open({
      message: res.data.errorMessage,
      type: 'error',
      position: 'top-right'
    });
  }
  return res;
}

export default {
  haveSuccess
};
