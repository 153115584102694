import axios from 'axios';
import Vue from 'vue';

axios.defaults.withCredentials = true;
// 發送前的動作
axios.interceptors.request.use((config) => config, (error) => {
  const value = error;
  return Promise.reject(value);
});

// 接收響應前的動作
axios.interceptors.response.use((response) => response, (error) => {
  const value = error.response;
  return Promise.resolve(value);
});

const getLocalStorage = () => {
  const ticket = localStorage.getItem('ticket');
  if (ticket !== null) {
    return ticket;
  }
  return '';
};

export default {
  post(url, value) {
    const params = {
      method: 'POST',
      baseURL: Vue.prototype.baseURL,
      url,
      data: value,
      timeout: 60000,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'x-user-ticket': getLocalStorage(),
      },
    };
    return axios(params).then((response) => response).catch((response) => response);
  },
  put(url, value) {
    const params = {
      method: 'PUT',
      baseURL: Vue.prototype.baseURL,
      url,
      data: value,
      timeout: 60000,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'x-user-ticket': getLocalStorage(),
      },
    };
    return axios(params).then((response) => response).catch((response) => response);
  },
  get(url) {
    const params = {
      method: 'GET',
      baseURL: Vue.prototype.baseURL,
      url,
      timeout: 60000,
      headers: {
        Pragma: 'no-cache',
        'Content-Type': 'application/json;charset=UTF-8',
        'x-user-ticket': getLocalStorage(),
      },
    };
    return axios(params).then((response) => response).catch((response) => response);
  },
  delete(url, val) {
    const params = {
      method: 'DELETE',
      baseURL: Vue.prototype.baseURL,
      url,
      timeout: 60000,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'x-user-ticket': getLocalStorage(),
      },
      data: val,
    };
    return axios(params).then((response) => response).catch((response) => response);
  },
  head(url) {
    const params = {
      method: 'HEAD',
      baseURL: Vue.prototype.baseURL,
      url,
      timeout: 60000,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'x-user-ticket': getLocalStorage(),
      },
    };
    return axios(params).then((response) => response).catch((response) => response);
  },
};
